.eye {
    position: absolute;
    top: 55%;
    right: 30px;
}

/* 
.loginCarousel {
    height: 100%;
} */
/* .loginCarousel .owl-stage {
    display: flex;
}

.loginCarousel .owl-item img {
    width: auto;
    height: 100%;
    max-height: 600px;
} */

