.carousel {
   position: relative;
   width: 100%;
   height: 100%;
}

.item {
   position: absolute;
   width: 100%;
   transition: all 0.2s ease-out;
   /* transition-timing-function: e; */
   /* height: 100%; */
}

.active {
   position: absolute;
   opacity: 1;
   left: 0;
}

.inactive {
   left: 100%;
   opacity: 0;
}

.previous {
   left: -100%;
   opacity: 0;
}

.pagination {
   width: 100%;
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   z-index: 100;
   margin-bottom: 49px;
}