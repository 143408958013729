.selectedIcon {
    position: absolute;
    right: -53px;
    top: -33px;
    z-index: -1;
    min-width: 105.32px;
}

.selectedNavLink {
    fill: black;
}

.selectedNavLink svg {
    fill: black;
    filter: invert(0.55) sepia(1) hue-rotate(200deg) saturate(5) brightness(1);
}
.translateUp svg {
    transform: translateY(-15px);
}
.navLink svg {
    transition: 0.2s;
}

.navLink svg {
    max-width: 30px;
}
@media only screen and (max-width: 1024px) {

    .selectedIcon {
        display: flex;
        top: -48px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        height: 92px;
    }
}
