.profileCard {
    background-image: linear-gradient(
        94.33deg,
        #7152eb 10.45%,
        rgba(247, 125, 86, 0.99) 84.22%
    );
    padding-top: 139px;
}

.imgContainer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
}
@media only screen and (min-width: 1024px) {
    .profileCard {
        border-radius: 20px;
        background-image: linear-gradient(
            94.33deg,
            #7152eb 10.45%,
            rgba(247, 125, 86, 0.99) 84.22%
        );
    }

    .imgContainer {
        position: static;
        left: 50%;
        transform: translate(0, 0);
        display: flex;
    }

    .imgContainer img {
        width: 165px;
        height: 165px;
        border-radius: 50%;
    }

    .profileCard {
        padding-top: 30px;
        padding-bottom: 18px;
    }
}
