#StudentDashboardHeader {
    gap: 16px;
    margin-bottom: 42px;
    margin-top: 37px;
}

#admissionExpert {
    background: linear-gradient(96.51deg, #7306e0 2.64%, #8d68f3 94.04%);
    border-radius: 20px;
    color: #f3f5f7;
    padding: 20px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 
#admissionExpert img {
    position: absolute;
    bottom: 0;
} */

#nextSession {
    background-color: #1c46d9;
    color: white;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 19px;
    padding-bottom: 26px;
}

#admissionExpert h1 {
    font-size: 21px;
    font-weight: 700;
    color: #f3f5f7;
}

#nextSession h1 {
    font-size: 21px;
    font-weight: 600;
    color: white;
    margin-left: 0;
}

#nextSession h2 {
    font-size: 18px;
    font-weight: 400;
    color: white;
    margin-left: 5px;
}

#nextSession h6 {
    font-size: 10px;
    font-weight: 600;
    margin-left: 5px;
}

#nextSession span {
    color: #4ce580;
}

#nextSession>div {
    gap: 15px;
}

#dateContainer {
    background-color: rgb(255, 255, 255, 20%);
    padding: 12px 19px;
    border-radius: 15px;
}

#dateContainer h1 {
    margin-right: 0;
    font-size: 48px;
    font-weight: 700;
    display: inline-block;
    position: relative;
}

#dateContainer sup {
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top: 16px;
    left: 97%;
}

#dateContainer h2 {
    font-size: 21px;
    font-weight: 600;
}

#tutorList::-webkit-scrollbar {
    width: 10px;
}

#tutorList::-webkit-scrollbar-track {
    background: #6da8ff26;
    margin-left: 16px;
    margin-right: 40px;
    border-radius: 20px;
}

#tutorList::-webkit-scrollbar-thumb {
    background-color: rgba(109, 168, 255, 0.6);
    border-radius: 20px;
}

#tutorList::-webkit-scrollbar-thumb:hover {
    background: rgba(40, 68, 109, 0.6);
}