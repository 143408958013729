#studentDashboard {
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 0;
    gap: 39px;
}

#chartContainer {
    overflow-x: scroll;
    background-color: #fff;
    padding: 0 10px;
    border-radius: 20px;
    margin-top: 19px;
}

#chartContainer::-webkit-scrollbar {
    width: 10px;
}

#chartContainer::-webkit-scrollbar-track {
    background: #6da8ff26;
    margin-left: 16px;
    margin-right: 40px;
    border-radius: 20px;
}

#chartContainer::-webkit-scrollbar-thumb {
    background-color: rgba(109, 168, 255, 0.6);
    border-radius: 20px;
}

#chartContainer::-webkit-scrollbar-thumb:hover {
    background: rgba(40, 68, 109, 0.6);
}

#studentDashboard h1 {
    font-size: 21px;
    font-weight: 600;
    color: #4715d7;
    margin-left: 20px;
    margin-right: 30px;
}

#studentDashboard label {
    background-color: #d9d9d980;
    padding: 10px 12px;
    border-radius: 10px;
}

#dropdownHeading {
    gap: 40px;
}

#studentDashboardRight {
    background-color: rgba(217, 217, 217, 40%);
    padding: 37px;
    border-radius: 20px 0 0 0;
}

.arrowDown {
    width: 16px;
}

#studentDashboardRight {
    padding-right: 10px;
}

#subjectList {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    /* display: inline-block; */
}

#subjectCheck {
    /* display: none; */
}

#subjectCheck:checked #subjectList {
    /* display: block; */
    background-color: #f00 !important;
}
