.downArrow {
    position: absolute;
    right: 20.5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
}

.options {
    position: absolute;
    top: calc(100% + 3px);
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    width: 100%;
    border-radius: 20px;
    max-height: 165px;
    overflow-y: auto;
    z-index: 100000;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.options > div:hover {
    background-color: rgba(197, 195, 195, 0.171);
}