@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.table-auto {
    border-collapse: separate;
    border-spacing: 0 4px;
    margin-top: 10px;
}

.table-auto td {
    border-style: solid none;
    padding: 10px;
}
.table-auto td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.table-auto td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

* {
    scrollbar-width: none;
    scrollbar-color: #546ea0 #856f6f;
}

.scrollbar-content::-webkit-scrollbar {
    height: 10px;
    border-radius: 20px;
}

.scrollbar-content::-webkit-scrollbar-track {
    background: rgba(109, 168, 255, 0.15);
    border-radius: 20px;
}

.scrollbar-content::-webkit-scrollbar-thumb {
    background: rgba(109, 168, 255, 0.6);
    border-radius: 20px;
    border: 3px solid rgba(109, 168, 255, 0.15);
}
.scrollbar-content::-webkit-scrollbar-thumb:hover {
    background: rgba(109, 168, 255, 0.6);
}

.scrollbar-vertical.scrollbar-content::-webkit-scrollbar {
    width: 10px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    --webkit-appearance: none;
    cursor: pointer;
    width: 15px;
    padding: 0px;
    margin: 0px;
    background-image: url(./assets/form/calendar.svg);
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    --webkit-appearance: none;
    display: none;
    cursor: pointer;
    /* width: 15px;
    padding: 0px;
    margin: 0px;
    background-image: url(./assets/form/eye-open.svg); */
}

.react-calendar {
    width: 100% !important;
}
