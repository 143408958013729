#parentDashboardHeader {
    padding: 50px 40px 30px 0px;
    gap: 40px;
}

@media only screen and (min-width: 1300px) {
    #parentDashboardHeader {
        gap: 0 78px;
    }
}


#explore {
    background-image: linear-gradient(to right, #7306e0, #8d68f3);
    border-radius: 20px;
    overflow: hidden;
    padding: 24px 0;
    position: relative;
}

#exploreBg img {
    width: 46%;
    position: absolute;
    bottom: 0;
}

#explore h2 {
    font-weight: 700;
    font-size: 20px;
    color: #f3f5f7;
    margin: 15px 34px;
    margin-top: 0;
    margin-right: 0;
    font-family: "GilroySemiBold", sans-serif;
}

#availableCredit {
    background-color: #4BBD94;
    padding: 23px;
    padding-bottom: 22px;
    border-radius: 16.4662px;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.05);
}

#availableCredit img {
    width: 21px;
    height: 21px;
}

#availableCredit h3 {
    font-family: "Inter", sans-serif;
    /* font-weight: 600; */
    /* font-size: 19.76px; */
    color: white;
}

#creditBalance {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 9px 30px;
    font-size: 36.23px;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 12.35px;
    margin: 18px 0;
}

.btnDark {
    display: inline-block;
    background-color:  #095740;
    width: 100%;
    padding: 8px 0;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: white;
    border-radius: 4.94px;
}

.knowMore {
    margin-left: 34px;
    background-color: #f3f5f7;
    border-radius: 5px;
    padding: 8px 15px;
    font-size: 11px;
}

#tutor {
    background-color: #7152eb;
    padding: 19px 23px;
    border-radius: 20px;
    color: white;
}

#tutor h2 {
    font-family: "Inter", sans-serif;
    font-size: 21px;
    font-weight: 600;
}

#tutor img {
    width: 165px;
    height: 165px;
    margin: 0 auto;
}

.tag {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 19px;
}

#tutor h3 {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 6px;
}

#tutor p {
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 12px;
    font-weight: 400;
}




.student {
    background: linear-gradient(95.17deg, #a455f2 3.84%, #981dac 92.55%);
    border-radius: 20px;
    /* padding: 13px 32px; */
    color: #fff;
}

.student h2 {
    font-family: "Inter", sans-serif;
    color: white !important;
    font-size: 18px;
    font-weight: 700;
    /* margin-bottom: 25px; */
}

.student img {
    width: 150px !important;
    height: 150px;
}