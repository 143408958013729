.container {
   display: block;
   position: relative;
   padding-left: 32px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   width: 22px;
   height: 22px;
 }
 
 .container input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
 }
 
 .checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 20px;
   width: 20px;
   border: 1.5px solid rgba(0, 0, 0, 0.2);
   border-radius: 6px;
 }
 
 .container input:checked~.checkmark {
   background-color: #7152EB;
 }
 
 .checkmark:after {
   content: "";
   position: absolute;
   display: none;
 }
 
 .container input:checked~.checkmark:after {
   display: block;
 }
 
 .container .checkmark:after {
   left: 5px;
   top: 1.2px;
   width: 6.2px;
   height: 12px;
   border: solid white;
   border-width: 0 2px 2px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
 }