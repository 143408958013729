#completeProfile {
    margin-top: 50px;
}

#completeProfile h1 {
    font-size: 21px;
    font-weight: 600;
    color: #4715d7;
    margin: 0;
    margin-left: 14px;
}

#arrowRight {
    width: 15px;
    height: 15px;
    transform: rotate(270deg);
}

#completeProfileHeader {
    justify-content: space-between;
}

#profileHeader {
    display: flex;
    margin-left: 14px;
    margin-top: 12px;
    justify-content: space-between;
}

#profileHeader {
    font-size: 18px;
    font-weight: 500;
}

#statusContainer {
    height: 9px;
    background-color: #d9d9d9;
    border-radius: 10px;
}

#statusBar {
    border-radius: 10px;
    height: 100%;
    background: #62dd43;
    margin-top: 10px;
}

#practiceTest {
    margin-top: 33px;
}

#practiceTest h1 {
    margin-bottom: 8px;
    margin-left: 21px;
}

#practiceTestContainer {
    background-color: #fbfbfb;
    border-radius: 20px;
    padding-top: 34px;
    padding-left: 28px;
    height: 236px;
    overflow-y: scroll;
}

#practiceTestContainer::-webkit-scrollbar {
    width: 10px;
}

#practiceTestContainer::-webkit-scrollbar-track {
    background: #6da8ff26;
    border-radius: 20px;
}

#practiceTestContainer::-webkit-scrollbar-thumb {
    background: #6da8ff99;
    border-radius: 20px;
}

#practiceTestContainer::-webkit-scrollbar-thumb:hover {
    background: #5582c599;
}
