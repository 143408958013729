.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 5000;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.cancelBtn {
    position: absolute;
    right: 20px;
    top: 20px;
}

@media only screen and (min-width: 768px) {
    .cancelBtn {
        right: 30.97px;
        top: 30px;
    }
}
