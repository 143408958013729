.filterItem {
   position: relative;
}

.filterItem:hover .icon {
   opacity: 1;
   pointer-events: all;
   transition: 0.2s;
}

.icon {
   pointer-events: none;
   opacity: 0;
   position: absolute;
   right: 0;
   top: 0;
   transform: translate(50%, -50%);
}