@import url("http://fonts.cdnfonts.com/css/gilroy-bold?styles=20876,20877,20878,20879,20880");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
    font-family: GilroyBlack;
    src: url(./assets/fonts/Gilroy-Black.ttf);
}
@font-face {
    font-family: GilroyBlackItalic;
    src: url(./assets/fonts/Gilroy-BlackItalic.ttf);
}
@font-face {
    font-family: GilroyBold;
    src: url(./assets/fonts/Gilroy-Bold.ttf);
}
@font-face {
    font-family: GilroyBolditalic;
    src: url(./assets/fonts/Gilroy-BoldItalic.ttf);
}
@font-face {
    font-family: GilroyExtraBold;
    src: url(./assets/fonts/Gilroy-ExtraBold.ttf);
}
@font-face {
    font-family: GilroyExtraBolditalic;
    src: url(./assets/fonts/Gilroy-Black.ttf);
}
@font-face {
    font-family: GilroyHeavy;
    src: url(./assets/fonts/Gilroy-Heavy.ttf);
}
@font-face {
    font-family: GilroyHeavyItalic;
    src: url(./assets/fonts/Gilroy-HeavyItalic.ttf);
}
@font-face {
    font-family: GilroyLight;
    src: url(./assets/fonts/Gilroy-Light.ttf);
}
@font-face {
    font-family: GilroyLightItalic;
    src: url(./assets/fonts/Gilroy-LightItalic.ttf);
}
@font-face {
    font-family: GilroyMedium;
    src: url(./assets/fonts/Gilroy-Medium.ttf);
}
@font-face {
    font-family: GilroyMediumItalic;
    src: url(./assets/fonts/Gilroy-MediumItalic.ttf);
}
@font-face {
    font-family: GilroyRegular;
    src: url(./assets/fonts/Gilroy-Regular.ttf);
}
@font-face {
    font-family: GilroySemiBold;
    src: url(./assets/fonts/Gilroy-SemiBold.ttf);
}
@font-face {
    font-family: GilroySemiBoldItalic;
    src: url(./assets/fonts/Gilroy-SemiBoldItalic.ttf);
}
@font-face {
    font-family: GilroyThin;
    src: url(./assets/fonts/Gilroy-Thin.ttf);
}
@font-face {
    font-family: GilroyThinItalic;
    src: url(./assets/fonts/Gilroy-ThinItalic.ttf);
}
@font-face {
    font-family: GilroyUltralight;
    src: url(./assets/fonts/Gilroy-UltraLight.ttf);
}
@font-face {
    font-family: GilroyUltralightItalic;
    src: url(./assets/fonts/Gilroy-UltraLightItalic.ttf);
}

body {
    background-color: #f3f5f7;
    height: 100vh;
    padding-right: 0;
    padding-bottom: 0;
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
}

nav .active {
    background-image: url("./assets/icons/navbar-active-icon.png");
    background-size: 100%;
    background-position-y: 50%;
    background-position-x: 50%;
}

.btn-gold {
    display: inline-block;
    background-color: #f6a429;
    padding: 10px 7px;
    border-radius: 6px;
    margin-top: 10px;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
} 

.concept {
    position: relative;
}

.concept .owl-nav {
    margin-top: 0 !important;
    position: absolute;
    top: -29px;
    visibility: hidden;
    right: 0;
}
